import React from 'react';
import { ChartData, ChartData_ChartType } from '../generated/protos/chart';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, ResponsiveContainer, Legend, Tooltip, Bar, BarChart } from "recharts";

interface ChartRendererProps {
    chartData: ChartData;
}

export default function ChartRenderer({ chartData }: ChartRendererProps) {
    const {data, labels} = React.useMemo(() => {
        const allKeyValues = chartData.dataPoints.flatMap((point) => point.fields);
        const uniqueFieldNames = Array.from(
            new Set(
            allKeyValues.map((keyValue) => keyValue.key)
        ));
        const labels = uniqueFieldNames.filter((key) => key !== chartData.xAxisDataKey);

        const data = chartData.dataPoints.map((point) => {
            const obj: { [key: string]: string | number | Date } = {};
            point.fields.forEach((field) => {
                const value = field.stringValue ?? field.floatValue ?? field.intValue ?? field.timestampValue;
                obj[field.key] = value!;
            });
            return obj;
        });

        return {data, labels};
    }, [chartData]);

    const title = chartData.title === "" ? undefined : chartData.title;
    const yAxisLabel = chartData.yAxisLabel === "" ? undefined : chartData.yAxisLabel;

    switch (chartData.chartType) {
        case ChartData_ChartType.BAR:
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <ChartContainer
                        data={data}
                        title={title}
                        xAxisKey={chartData.xAxisDataKey}
                        labels={labels}
                        xAxisLabel={chartData.xAxisLabel}
                        yAxisLabel={yAxisLabel}
                        chartType="bar"
                    />
                </ResponsiveContainer>
            )
        case ChartData_ChartType.LINE:
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <ChartContainer
                        data={data}
                        title={title}
                        xAxisKey={chartData.xAxisDataKey}
                        labels={labels}
                        xAxisLabel={chartData.xAxisLabel}
                        yAxisLabel={yAxisLabel}
                        chartType="line"
                    />
                </ResponsiveContainer>
            )
        default:
            return null;
    }
}

interface ChartProps {
    data: Array<{[key: string]: string | number | Date}>,
    title?: string,
    xAxisLabel: string,
    yAxisLabel?: string,
    xAxisKey: string,
    labels: string[],
    chartType: 'line' | 'bar'
}

function ChartContainer({ data, title, xAxisKey, labels, xAxisLabel, yAxisLabel, chartType }: ChartProps) {
    const uniqueStrokes = getUniqueStrokes();
    const ChartComponent = chartType === 'line' ? LineChart : BarChart;

    const topMargin = title ? 40 : 20;
    const leftMargin = yAxisLabel ? 60 : 10;

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ChartComponent
                data={data}
                margin={{ top: topMargin, right: 20, left: leftMargin, bottom: 20 }}
            >
                {title && (
                    <text x="50%" y="20" textAnchor="middle" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {title}
                    </text>
                )}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxisKey}
                    label={{ value: xAxisLabel, position: 'insideBottom', offset: -10 }}
                />
                <YAxis
                    label={yAxisLabel ? {
                        value: yAxisLabel,
                        angle: -90,
                        position: 'insideLeft',
                        offset: -10,
                        style: {
                            textAnchor: 'middle'
                        }
                    } : undefined}
                />
                <Tooltip />
                <Legend
                    layout="vertical"
                    align="center"
                    verticalAlign="top"
                    wrapperStyle={{
                        width: '100%',
                        paddingBottom: '10px'
                    }}
                />
                {labels.map((labelKey, index) => (
                    chartType === 'line' ? (
                        <Line
                            type="monotone"
                            dataKey={labelKey}
                            stroke={uniqueStrokes[index % uniqueStrokes.length]}
                            key={labelKey}
                        />
                    ) : (
                        <Bar
                            dataKey={labelKey}
                            fill={uniqueStrokes[index % uniqueStrokes.length]}
                            key={labelKey}
                        />
                    )
                ))}
            </ChartComponent>
        </ResponsiveContainer>
    );
}

function getUniqueStrokes(): string[] {
  return [
    "#8884d8", // Purple
    "#82ca9d", // Green
    "#ff7300", // Orange
    "#0088fe", // Blue
    "#ff6b81", // Pink
    "#00c49f", // Teal
    "#ffbb28", // Yellow
    "#a4de6c", // Light Green
    "#d0679d", // Rose
    "#40a9ff", // Light Blue
    "#ffa07a", // Light Salmon
    "#ba68c8", // Light Purple
    "#4db6ac", // Light Teal
    "#f57c00", // Dark Orange
    "#9575cd"  // Muted Purple
  ];
}
