// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/chat.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";
import { ChartData } from "./chart";

export const protobufPackage = "finllm";

export interface UserMessage {
  content: string;
  wasRecommended: boolean;
}

export interface Support {
  evidenceIds: string[];
  text: string;
}

export interface LLMMessage {
  response: string;
  model: string;
  reasoning: string;
  supportPoints: Support[];
  followUpQuestions: string[];
  relationshipIds: string[];
}

export interface ChatMessage {
  sessionId: string;
  messageId: string;
  asOfTimestamp: Date | undefined;
  userMessage?: UserMessage | undefined;
  llmMessage?: LLMMessage | undefined;
  chartData?: ChartData | undefined;
}

function createBaseUserMessage(): UserMessage {
  return { content: "", wasRecommended: false };
}

export const UserMessage: MessageFns<UserMessage> = {
  encode(message: UserMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.content !== "") {
      writer.uint32(10).string(message.content);
    }
    if (message.wasRecommended !== false) {
      writer.uint32(16).bool(message.wasRecommended);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.wasRecommended = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMessage {
    return {
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      wasRecommended: isSet(object.wasRecommended) ? globalThis.Boolean(object.wasRecommended) : false,
    };
  },

  toJSON(message: UserMessage): unknown {
    const obj: any = {};
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.wasRecommended !== false) {
      obj.wasRecommended = message.wasRecommended;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMessage>, I>>(base?: I): UserMessage {
    return UserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMessage>, I>>(object: I): UserMessage {
    const message = createBaseUserMessage();
    message.content = object.content ?? "";
    message.wasRecommended = object.wasRecommended ?? false;
    return message;
  },
};

function createBaseSupport(): Support {
  return { evidenceIds: [], text: "" };
}

export const Support: MessageFns<Support> = {
  encode(message: Support, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.evidenceIds) {
      writer.uint32(10).string(v!);
    }
    if (message.text !== "") {
      writer.uint32(18).string(message.text);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Support {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evidenceIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Support {
    return {
      evidenceIds: globalThis.Array.isArray(object?.evidenceIds)
        ? object.evidenceIds.map((e: any) => globalThis.String(e))
        : [],
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: Support): unknown {
    const obj: any = {};
    if (message.evidenceIds?.length) {
      obj.evidenceIds = message.evidenceIds;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Support>, I>>(base?: I): Support {
    return Support.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Support>, I>>(object: I): Support {
    const message = createBaseSupport();
    message.evidenceIds = object.evidenceIds?.map((e) => e) || [];
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseLLMMessage(): LLMMessage {
  return { response: "", model: "", reasoning: "", supportPoints: [], followUpQuestions: [], relationshipIds: [] };
}

export const LLMMessage: MessageFns<LLMMessage> = {
  encode(message: LLMMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.response !== "") {
      writer.uint32(10).string(message.response);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.reasoning !== "") {
      writer.uint32(26).string(message.reasoning);
    }
    for (const v of message.supportPoints) {
      Support.encode(v!, writer.uint32(34).fork()).join();
    }
    for (const v of message.followUpQuestions) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.relationshipIds) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LLMMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLLMMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.response = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reasoning = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.supportPoints.push(Support.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.followUpQuestions.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.relationshipIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LLMMessage {
    return {
      response: isSet(object.response) ? globalThis.String(object.response) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      reasoning: isSet(object.reasoning) ? globalThis.String(object.reasoning) : "",
      supportPoints: globalThis.Array.isArray(object?.supportPoints)
        ? object.supportPoints.map((e: any) => Support.fromJSON(e))
        : [],
      followUpQuestions: globalThis.Array.isArray(object?.followUpQuestions)
        ? object.followUpQuestions.map((e: any) => globalThis.String(e))
        : [],
      relationshipIds: globalThis.Array.isArray(object?.relationshipIds)
        ? object.relationshipIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: LLMMessage): unknown {
    const obj: any = {};
    if (message.response !== "") {
      obj.response = message.response;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.reasoning !== "") {
      obj.reasoning = message.reasoning;
    }
    if (message.supportPoints?.length) {
      obj.supportPoints = message.supportPoints.map((e) => Support.toJSON(e));
    }
    if (message.followUpQuestions?.length) {
      obj.followUpQuestions = message.followUpQuestions;
    }
    if (message.relationshipIds?.length) {
      obj.relationshipIds = message.relationshipIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LLMMessage>, I>>(base?: I): LLMMessage {
    return LLMMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LLMMessage>, I>>(object: I): LLMMessage {
    const message = createBaseLLMMessage();
    message.response = object.response ?? "";
    message.model = object.model ?? "";
    message.reasoning = object.reasoning ?? "";
    message.supportPoints = object.supportPoints?.map((e) => Support.fromPartial(e)) || [];
    message.followUpQuestions = object.followUpQuestions?.map((e) => e) || [];
    message.relationshipIds = object.relationshipIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseChatMessage(): ChatMessage {
  return {
    sessionId: "",
    messageId: "",
    asOfTimestamp: undefined,
    userMessage: undefined,
    llmMessage: undefined,
    chartData: undefined,
  };
}

export const ChatMessage: MessageFns<ChatMessage> = {
  encode(message: ChatMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.sessionId !== "") {
      writer.uint32(10).string(message.sessionId);
    }
    if (message.messageId !== "") {
      writer.uint32(18).string(message.messageId);
    }
    if (message.asOfTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.asOfTimestamp), writer.uint32(26).fork()).join();
    }
    if (message.userMessage !== undefined) {
      UserMessage.encode(message.userMessage, writer.uint32(34).fork()).join();
    }
    if (message.llmMessage !== undefined) {
      LLMMessage.encode(message.llmMessage, writer.uint32(42).fork()).join();
    }
    if (message.chartData !== undefined) {
      ChartData.encode(message.chartData, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChatMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messageId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.asOfTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userMessage = UserMessage.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.llmMessage = LLMMessage.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.chartData = ChartData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatMessage {
    return {
      sessionId: isSet(object.sessionId) ? globalThis.String(object.sessionId) : "",
      messageId: isSet(object.messageId) ? globalThis.String(object.messageId) : "",
      asOfTimestamp: isSet(object.asOfTimestamp) ? fromJsonTimestamp(object.asOfTimestamp) : undefined,
      userMessage: isSet(object.userMessage) ? UserMessage.fromJSON(object.userMessage) : undefined,
      llmMessage: isSet(object.llmMessage) ? LLMMessage.fromJSON(object.llmMessage) : undefined,
      chartData: isSet(object.chartData) ? ChartData.fromJSON(object.chartData) : undefined,
    };
  },

  toJSON(message: ChatMessage): unknown {
    const obj: any = {};
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    if (message.messageId !== "") {
      obj.messageId = message.messageId;
    }
    if (message.asOfTimestamp !== undefined) {
      obj.asOfTimestamp = message.asOfTimestamp.toISOString();
    }
    if (message.userMessage !== undefined) {
      obj.userMessage = UserMessage.toJSON(message.userMessage);
    }
    if (message.llmMessage !== undefined) {
      obj.llmMessage = LLMMessage.toJSON(message.llmMessage);
    }
    if (message.chartData !== undefined) {
      obj.chartData = ChartData.toJSON(message.chartData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatMessage>, I>>(base?: I): ChatMessage {
    return ChatMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatMessage>, I>>(object: I): ChatMessage {
    const message = createBaseChatMessage();
    message.sessionId = object.sessionId ?? "";
    message.messageId = object.messageId ?? "";
    message.asOfTimestamp = object.asOfTimestamp ?? undefined;
    message.userMessage = (object.userMessage !== undefined && object.userMessage !== null)
      ? UserMessage.fromPartial(object.userMessage)
      : undefined;
    message.llmMessage = (object.llmMessage !== undefined && object.llmMessage !== null)
      ? LLMMessage.fromPartial(object.llmMessage)
      : undefined;
    message.chartData = (object.chartData !== undefined && object.chartData !== null)
      ? ChartData.fromPartial(object.chartData)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
