// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/chart.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "finllm";

export interface KeyValue {
  key: string;
  stringValue?: string | undefined;
  floatValue?: number | undefined;
  intValue?: number | undefined;
  timestampValue?: Date | undefined;
}

export interface ChartDataPoint {
  fields: KeyValue[];
}

export interface ChartData {
  chartType: ChartData_ChartType;
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  dataPoints: ChartDataPoint[];
  description: string;
  xAxisDataKey: string;
}

export enum ChartData_ChartType {
  UNSPECIFIED = 0,
  BAR = 1,
  LINE = 2,
  UNRECOGNIZED = -1,
}

export function chartData_ChartTypeFromJSON(object: any): ChartData_ChartType {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return ChartData_ChartType.UNSPECIFIED;
    case 1:
    case "BAR":
      return ChartData_ChartType.BAR;
    case 2:
    case "LINE":
      return ChartData_ChartType.LINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChartData_ChartType.UNRECOGNIZED;
  }
}

export function chartData_ChartTypeToJSON(object: ChartData_ChartType): string {
  switch (object) {
    case ChartData_ChartType.UNSPECIFIED:
      return "UNSPECIFIED";
    case ChartData_ChartType.BAR:
      return "BAR";
    case ChartData_ChartType.LINE:
      return "LINE";
    case ChartData_ChartType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseKeyValue(): KeyValue {
  return { key: "", stringValue: undefined, floatValue: undefined, intValue: undefined, timestampValue: undefined };
}

export const KeyValue: MessageFns<KeyValue> = {
  encode(message: KeyValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.stringValue !== undefined) {
      writer.uint32(18).string(message.stringValue);
    }
    if (message.floatValue !== undefined) {
      writer.uint32(29).float(message.floatValue);
    }
    if (message.intValue !== undefined) {
      writer.uint32(32).int32(message.intValue);
    }
    if (message.timestampValue !== undefined) {
      Timestamp.encode(toTimestamp(message.timestampValue), writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KeyValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stringValue = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.floatValue = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.intValue = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timestampValue = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyValue {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      stringValue: isSet(object.stringValue) ? globalThis.String(object.stringValue) : undefined,
      floatValue: isSet(object.floatValue) ? globalThis.Number(object.floatValue) : undefined,
      intValue: isSet(object.intValue) ? globalThis.Number(object.intValue) : undefined,
      timestampValue: isSet(object.timestampValue) ? fromJsonTimestamp(object.timestampValue) : undefined,
    };
  },

  toJSON(message: KeyValue): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.stringValue !== undefined) {
      obj.stringValue = message.stringValue;
    }
    if (message.floatValue !== undefined) {
      obj.floatValue = message.floatValue;
    }
    if (message.intValue !== undefined) {
      obj.intValue = Math.round(message.intValue);
    }
    if (message.timestampValue !== undefined) {
      obj.timestampValue = message.timestampValue.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyValue>, I>>(base?: I): KeyValue {
    return KeyValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyValue>, I>>(object: I): KeyValue {
    const message = createBaseKeyValue();
    message.key = object.key ?? "";
    message.stringValue = object.stringValue ?? undefined;
    message.floatValue = object.floatValue ?? undefined;
    message.intValue = object.intValue ?? undefined;
    message.timestampValue = object.timestampValue ?? undefined;
    return message;
  },
};

function createBaseChartDataPoint(): ChartDataPoint {
  return { fields: [] };
}

export const ChartDataPoint: MessageFns<ChartDataPoint> = {
  encode(message: ChartDataPoint, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.fields) {
      KeyValue.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChartDataPoint {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChartDataPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields.push(KeyValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChartDataPoint {
    return {
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => KeyValue.fromJSON(e)) : [],
    };
  },

  toJSON(message: ChartDataPoint): unknown {
    const obj: any = {};
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => KeyValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChartDataPoint>, I>>(base?: I): ChartDataPoint {
    return ChartDataPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChartDataPoint>, I>>(object: I): ChartDataPoint {
    const message = createBaseChartDataPoint();
    message.fields = object.fields?.map((e) => KeyValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChartData(): ChartData {
  return { chartType: 0, title: "", xAxisLabel: "", yAxisLabel: "", dataPoints: [], description: "", xAxisDataKey: "" };
}

export const ChartData: MessageFns<ChartData> = {
  encode(message: ChartData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chartType !== 0) {
      writer.uint32(8).int32(message.chartType);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.xAxisLabel !== "") {
      writer.uint32(26).string(message.xAxisLabel);
    }
    if (message.yAxisLabel !== "") {
      writer.uint32(34).string(message.yAxisLabel);
    }
    for (const v of message.dataPoints) {
      ChartDataPoint.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.xAxisDataKey !== "") {
      writer.uint32(58).string(message.xAxisDataKey);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChartData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChartData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chartType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.xAxisLabel = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.yAxisLabel = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dataPoints.push(ChartDataPoint.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.xAxisDataKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChartData {
    return {
      chartType: isSet(object.chartType) ? chartData_ChartTypeFromJSON(object.chartType) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      xAxisLabel: isSet(object.xAxisLabel) ? globalThis.String(object.xAxisLabel) : "",
      yAxisLabel: isSet(object.yAxisLabel) ? globalThis.String(object.yAxisLabel) : "",
      dataPoints: globalThis.Array.isArray(object?.dataPoints)
        ? object.dataPoints.map((e: any) => ChartDataPoint.fromJSON(e))
        : [],
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      xAxisDataKey: isSet(object.xAxisDataKey) ? globalThis.String(object.xAxisDataKey) : "",
    };
  },

  toJSON(message: ChartData): unknown {
    const obj: any = {};
    if (message.chartType !== 0) {
      obj.chartType = chartData_ChartTypeToJSON(message.chartType);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.xAxisLabel !== "") {
      obj.xAxisLabel = message.xAxisLabel;
    }
    if (message.yAxisLabel !== "") {
      obj.yAxisLabel = message.yAxisLabel;
    }
    if (message.dataPoints?.length) {
      obj.dataPoints = message.dataPoints.map((e) => ChartDataPoint.toJSON(e));
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.xAxisDataKey !== "") {
      obj.xAxisDataKey = message.xAxisDataKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChartData>, I>>(base?: I): ChartData {
    return ChartData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChartData>, I>>(object: I): ChartData {
    const message = createBaseChartData();
    message.chartType = object.chartType ?? 0;
    message.title = object.title ?? "";
    message.xAxisLabel = object.xAxisLabel ?? "";
    message.yAxisLabel = object.yAxisLabel ?? "";
    message.dataPoints = object.dataPoints?.map((e) => ChartDataPoint.fromPartial(e)) || [];
    message.description = object.description ?? "";
    message.xAxisDataKey = object.xAxisDataKey ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
