import { useMemo, useState, ReactNode } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Chip,
  SelectChangeEvent,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { format, isAfter, isBefore, startOfToday, addMonths } from "date-fns";
import { useDocumentContext } from "../../context/DocumentContext";
import { Chunk, Entity, Value } from "../../generated/protos/chunk";

interface Filters {
  companies: string[];
  metrics: string[];
  qualifiers: string[];
  monthRange: number;
}

interface MetricChange {
  id: string;
  company: string;
  metricName: string;
  qualifier: string;
  oldValue: string;
  newValue: string;
  targetDate: Date;
  oldAsOfDate: Date;
  newAsOfDate: Date;
  chunkId: string;
}

interface Column {
  id: keyof MetricChange;
  label: string;
  align?: "left" | "right" | "center";
  sortable?: boolean;
  format?: (value: any) => string;
  renderCell?: (value: any) => ReactNode;
}

const columns: Column[] = [
  {
    id: "company",
    label: "Company",
    sortable: true,
    renderCell: (value: string) => value,
  },
  {
    id: "metricName",
    label: "Metric",
    sortable: true,
    renderCell: (value: string) => value,
  },
  {
    id: "qualifier",
    label: "Qualifier",
    sortable: true,
    renderCell: (value: string) => value,
  },
  {
    id: "oldValue",
    label: "Previous Call",
    align: "right",
    renderCell: (value: string) => value,
  },
  {
    id: "newValue",
    label: "Updated Call",
    align: "right",
    renderCell: (value: string) => value,
  },
  {
    id: "targetDate",
    label: "Target Date",
    align: "center",
    sortable: true,
    renderCell: (value: Date) => format(value, "MMM dd, yyyy"),
  },
  {
    id: "oldAsOfDate",
    label: "Previous As Of",
    align: "center",
    renderCell: (value: Date) => format(value, "MMM dd, yyyy"),
  },
  {
    id: "newAsOfDate",
    label: "Updated As Of",
    align: "center",
    sortable: true,
    renderCell: (value: Date) => format(value, "MMM dd, yyyy"),
  },
];

function processChunks(
  chunks: Chunk[],
  today: Date,
  futureLimit: Date
): MetricChange[] {
  // Step 1: Create a map to group chunks by company + metric + qualifier + endDate
  const groupedChunks = new Map<string, Chunk[]>();

  chunks.forEach((chunk) => {
    const entity = chunk.entityRelationship?.toEntity;
    const company = chunk.entityRelationship?.fromEntity?.entityName;
    const endTimestamp = chunk.entityRelationship?.timeSlice?.endTimestamp;
    const qualifier = chunk.entityRelationship?.value?.qualifier || "NONE";

    if (!entity || !company || !endTimestamp) return;

    const endDate = new Date(endTimestamp);
    if (!isAfter(endDate, today) || !isBefore(endDate, futureLimit)) return;

    const key = `${company}-${getMetricName(
      entity
    )}-${qualifier}-${endTimestamp}`;
    if (!groupedChunks.has(key)) {
      groupedChunks.set(key, []);
    }
    groupedChunks.get(key)?.push(chunk);
  });

  // Step 2: Process each group to find changes
  const changes: MetricChange[] = [];

  groupedChunks.forEach((groupChunks, key) => {
    // Sort chunks by asOfTimestamp
    const sortedChunks = groupChunks.sort((a, b) => {
      const asOfA = new Date(
        a.entityRelationship?.timeSlice?.asOfTimestamp || 0
      );
      const asOfB = new Date(
        b.entityRelationship?.timeSlice?.asOfTimestamp || 0
      );
      return asOfA.getTime() - asOfB.getTime();
    });

    // Look for value changes between consecutive chunks
    for (let i = 0; i < sortedChunks.length - 1; i++) {
      const oldChunk = sortedChunks[i];
      const newChunk = sortedChunks[i + 1];

      const oldValue = oldChunk.entityRelationship?.value;
      const newValue = newChunk.entityRelationship?.value;

      if (oldValue?.value === newValue?.value) continue;

      changes.push({
        id: `${key}-${i}`,
        company: oldChunk.entityRelationship?.fromEntity?.entityName || "",
        metricName: getMetricName(
          oldChunk.entityRelationship?.toEntity as Entity
        ),
        qualifier: oldValue?.qualifier || "",
        oldValue: formatValue(oldValue as Value),
        newValue: formatValue(newValue as Value),
        targetDate: new Date(
          oldChunk.entityRelationship?.timeSlice?.endTimestamp || ""
        ),
        oldAsOfDate: new Date(
          oldChunk.entityRelationship?.timeSlice?.asOfTimestamp || ""
        ),
        newAsOfDate: new Date(
          newChunk.entityRelationship?.timeSlice?.asOfTimestamp || ""
        ),
        chunkId: newChunk.chunkId,
      });
    }
  });

  return changes;
}

function FilterSection({
  label,
  options,
  selected,
  onChange,
}: {
  label: string;
  options: string[];
  selected: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
}) {
  return (
    <FormControl size="small" sx={{ minWidth: 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={onChange}
        label={label}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function getMetricName(entity: Entity): string {
  if (!entity.entitySubcomponents.length) return entity.entityName;
  const lastSubcomponent =
    entity.entitySubcomponents[entity.entitySubcomponents.length - 1];
  return `${lastSubcomponent} ${entity.entityName}`;
}

function formatValue(value: Value): string {
  if (!value?.value) return "";

  const numValue = parseFloat(value.value);
  if (isNaN(numValue)) return value.value;

  const absValue = Math.abs(numValue);
  if (absValue >= 1_000_000) {
    return `${(numValue / 1_000_000).toFixed(1).replace(/\.0$/, "")}M`;
  }
  if (absValue >= 1_000) {
    return `${(numValue / 1_000).toFixed(1).replace(/\.0$/, "")}k`;
  }
  return value.value;
}

function getChangePercentage(
  oldValue: string,
  newValue: string
): number | null {
  const oldNum = parseFloat(oldValue.replace(/[^0-9.-]+/g, ""));
  const newNum = parseFloat(newValue.replace(/[^0-9.-]+/g, ""));

  if (isNaN(oldNum) || isNaN(newNum) || oldNum === 0) return null;

  const percentChange = ((newNum - oldNum) / Math.abs(oldNum)) * 100;

  if (Math.abs(percentChange) > 50) {
    console.warn(
      `Unrealistic change detected: ${percentChange}% change from ${oldValue} to ${newValue}`
    );
    return null;
  }

  return percentChange;
}

function getChangeColor(percentage: number | null): string {
  if (percentage === null) return "#757575";
  return percentage > 0 ? "#4caf50" : percentage < 0 ? "#f44336" : "#757575";
}
export default function RecentCalls() {
  const { allDocuments: documentExtractions, selectedDocuments } =
    useDocumentContext();
  const [orderBy, setOrderBy] = useState<keyof MetricChange>("newAsOfDate");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [filters, setFilters] = useState<Filters>({
    companies: [],
    metrics: [],
    qualifiers: [],
    monthRange: 2,
  });

  const today = useMemo(() => startOfToday(), []);
  const futureLimit = useMemo(
    () => addMonths(today, filters.monthRange),
    [today, filters.monthRange]
  );

  const monthRangeOptions = [
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Months" },
    { value: 3, label: "3 Months" },
    { value: 6, label: "6 Months" },
    { value: 12, label: "1 Year" },
  ];

  const handleMonthRangeChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value;
    setFilters((prev) => ({
      ...prev,
      monthRange: typeof value === "string" ? parseInt(value) : value,
    }));
  };

  const metricChanges = useMemo(() => {
    const chunks = documentExtractions
      .filter((doc) =>
        selectedDocuments.some(
          (selectedDoc) =>
            selectedDoc.documentId === doc.documentMetadata?.documentId
        )
      )
      .flatMap((doc) => doc.relationshipChunks);

    return processChunks(chunks, today, futureLimit);
  }, [documentExtractions, selectedDocuments, today, futureLimit]);

  const filterOptions = useMemo(() => {
    const options = {
      companies: new Set<string>(),
      metrics: new Set<string>(),
      qualifiers: new Set<string>(),
    };

    metricChanges.forEach((change) => {
      options.companies.add(change.company);
      options.metrics.add(change.metricName);
      options.qualifiers.add(change.qualifier);
    });

    return {
      companies: Array.from(options.companies).sort(),
      metrics: Array.from(options.metrics).sort(),
      qualifiers: Array.from(options.qualifiers).sort(),
    };
  }, [metricChanges]);

  const filteredAndSortedChanges = useMemo(() => {
    const filtered = metricChanges.filter((change) => {
      // First check if the change percentage is realistic
      const changePercent = getChangePercentage(
        change.oldValue,
        change.newValue
      );
      if (changePercent === null) return false;

      const matchesCompany =
        filters.companies.length === 0 ||
        filters.companies.includes(change.company);
      const matchesMetric =
        filters.metrics.length === 0 ||
        filters.metrics.includes(change.metricName);
      const matchesQualifier =
        filters.qualifiers.length === 0 ||
        filters.qualifiers.includes(change.qualifier);

      return matchesCompany && matchesMetric && matchesQualifier;
    });

    return filtered.sort((a, b) => {
      let comparison;
      if (
        orderBy === "targetDate" ||
        orderBy === "oldAsOfDate" ||
        orderBy === "newAsOfDate"
      ) {
        comparison = a[orderBy].getTime() - b[orderBy].getTime();
      } else {
        comparison = String(a[orderBy]).localeCompare(String(b[orderBy]));
      }
      return order === "asc" ? comparison : -comparison;
    });
  }, [metricChanges, filters, order, orderBy]);

  const handleFilterChange =
    (filterType: keyof Filters) => (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;
      setFilters((prev) => ({
        ...prev,
        [filterType]: typeof value === "string" ? value.split(",") : value,
      }));
    };

  const handleSort = (property: keyof MetricChange) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ height: "100%", minWidth: 500, maxWidth: "50%", flex: "1 0 0" }}>
      <Paper
        sx={{
          height: "100%",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ p: 3, pb: 2 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: 700, letterSpacing: "-0.02em" }}
          >
            Recent Call Updates
          </Typography>

          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            {`Showing changes from ${format(today, "MMM dd, yyyy")} to ${format(
              futureLimit,
              "MMM dd, yyyy"
            )}`}
          </Typography>

          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Time Range</InputLabel>
              <Select
                value={filters.monthRange}
                onChange={handleMonthRangeChange}
                label="Time Range"
              >
                {monthRangeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FilterSection
              label="Company"
              options={filterOptions.companies}
              selected={filters.companies}
              onChange={handleFilterChange("companies")}
            />
            <FilterSection
              label="Metric"
              options={filterOptions.metrics}
              selected={filters.metrics}
              onChange={handleFilterChange("metrics")}
            />
            <FilterSection
              label="Qualifier"
              options={filterOptions.qualifiers}
              selected={filters.qualifiers}
              onChange={handleFilterChange("qualifiers")}
            />
          </Stack>
        </Box>
        <Box sx={{ px: 3, pb: 3, flex: 1 }}>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 320, // Match TradeRecommendations height
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              background: "linear-gradient(145deg, #ffffff, #f6f7f9)",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
              overflow: "auto",
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: "#f5f5f5",
                        fontWeight: "bold",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    align="right"
                    sx={{
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Change
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedChanges.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center" sx={{ py: 4 }}>
                      <Typography variant="h6" color="textSecondary">
                        No changes found
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Select documents to view upcoming metric call changes
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredAndSortedChanges.map((change) => {
                    const changePercent = getChangePercentage(
                      change.oldValue,
                      change.newValue
                    );
                    const changeColor = getChangeColor(changePercent);

                    return (
                      <TableRow
                        key={change.id}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        {columns.map((column) => (
                          <TableCell key={column.id} align={column.align}>
                            {column.renderCell?.(change[column.id])}
                          </TableCell>
                        ))}
                        <TableCell
                          align="right"
                          sx={{
                            color: changeColor,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: 0.5,
                          }}
                        >
                          {changePercent !== null && (
                            <>
                              {changePercent > 0 ? (
                                <ArrowUpwardIcon sx={{ fontSize: 16 }} />
                              ) : changePercent < 0 ? (
                                <ArrowDownwardIcon sx={{ fontSize: 16 }} />
                              ) : null}
                              {Math.abs(changePercent).toFixed(1)}%
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}
