import React, { useState, useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Stack,
  Popover,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MetricTableRow } from "./Dashboard/tableDataAggregation";
import CitationModal from "./CitationModal";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { useDocumentContext } from "../context/DocumentContext";
import JPMorganChase from "./assets/jpmorgan-chase";
import Bofa from "./assets/bofa";
import GoldmanSachs from "./assets/goldman-sachs";
import MorganStanley from "./assets/morgan-stanley";
import WellsFargo from "./assets/wells-fargo";
import Citigroup from "./assets/citigroup";
import Barclays from "./assets/barclays";
import DeutscheBank from "./assets/deutsche-bank";
import CreditSuisse from "./assets/credit-suisse";
import UBS from "./assets/ubs";
import HSBC from "./assets/hsbc";
import Nomura from "./assets/nomura";
import { Company } from "../generated/protos/chunk";
import ChartRenderer from './ChartRenderer';
import { ChartData, ChartData_ChartType } from '../generated/protos/chart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';

const VirtuosoTableComponents: TableComponents<MetricTableRow> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{
        borderCollapse: "separate",
        tableLayout: "fixed",
        "& .MuiTableCell-root": {
          padding: "8px",
          whiteSpace: "normal",
          lineHeight: "1.2",
        },
      }}
    />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: ({ item: _item, ...props }) => (
    <TableRow
      {...props}
      sx={{
        "&:nth-of-type(odd)": {
          backgroundColor: "#fafafa",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#ffffff",
        },
      }}
    />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

interface MetricTableProps {
  metricTableRows: MetricTableRow[];
  tableName: string;
  preview?: boolean;
  showMetricName?: boolean;
  isSimpleMode?: boolean;
  disableFilters?: boolean;
}

type SortKey = keyof MetricTableRow;

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface Filters {
  text: { column: SortKey; value: string }[];
  qualifiers: string[];
  targetDateRange: DateRange;
}

type MetricTableColumn = {
  key: keyof Omit<
    MetricTableRow,
    "chunkId" | "pageNum" | "publishingOrganization"
  >;
  label: string;
};

type ColumnWidthsType = Record<
  keyof Omit<MetricTableRow, "chunkId" | "pageNum" | "publishingOrganization">,
  string
>;

type ViewType = 'table' | 'chart';

export const MetricTable: React.FC<MetricTableProps> = ({
  metricTableRows,
  tableName,
  preview = false,
  showMetricName = false,
  isSimpleMode = false,
  disableFilters = false,
}) => {
  const { setQueryEnabled } = useDocumentContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCellData, setSelectedCellData] =
    useState<MetricTableRow | null>(null);
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  // Filter states
  const [filters, setFilters] = useState<Filters>({
    text: [],
    qualifiers: [],
    targetDateRange: { start: null, end: null },
  });

  // UI states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColumn, setSelectedColumn] = useState<SortKey | null>(null);
  const [filterValue, setFilterValue] = useState("");
  const [qualifierAnchorEl, setQualifierAnchorEl] =
    useState<null | HTMLElement>(null);
  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const [activeDateFilter, setActiveDateFilter] = useState<"target" | null>(
    null
  );
  const [orderBy, setOrderBy] = useState<SortKey>("targetDate");
  const [viewType, setViewType] = useState<ViewType>('table');

  const uniqueQualifiers = useMemo(
    () => Array.from(new Set(metricTableRows.map((row) => row.qualifier))),
    [metricTableRows]
  );

  const columns = useMemo((): MetricTableColumn[] => {
    const allColumns: MetricTableColumn[] = [
      { key: "metricName", label: "Metric" },
      { key: "firm", label: "Firm" },
      { key: "metricValue", label: "Metric Value" },
      { key: "qualifier", label: "Qualifier" },
      { key: "targetDate", label: "Target Date" },
    ];

    if (!showMetricName) {
      return allColumns.filter((col) => col.key !== "metricName");
    } else {
      return allColumns.filter((col) => col.key !== "firm");
    }
  }, [showMetricName]);

  const columnWidths = useMemo((): ColumnWidthsType => {
    const defaultWidths = {
      metricName: "0%",
      firm: "30%",
      metricValue: "25%",
      qualifier: "20%",
      targetDate: "25%",
    };

    if (showMetricName) {
      return {
        metricName: "30%",
        firm: "0%",
        metricValue: "25%",
        qualifier: "20%",
        targetDate: "25%",
      };
    }

    return defaultWidths;
  }, [showMetricName]);

  // Sorting handler
  const handleSort = (property: SortKey) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Filter handlers
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQualifierFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setQualifierAnchorEl(event.currentTarget);
  };

  const handleDateFilterClick = (
    event: React.MouseEvent<HTMLElement>,
    type: "target"
  ) => {
    setActiveDateFilter(type);
    setDateAnchorEl(event.currentTarget);
  };

  const handleQualifierChange = (qualifier: string) => {
    setFilters((prev) => ({
      ...prev,
      qualifiers: prev.qualifiers.includes(qualifier)
        ? prev.qualifiers.filter((q) => q !== qualifier)
        : [...prev.qualifiers, qualifier],
    }));
  };

  const handleDateRangeChange = (date: Date | null, type: "start" | "end") => {
    if (activeDateFilter) {
      const rangeKey = `${activeDateFilter}DateRange` as keyof Pick<
        Filters,
        "targetDateRange"
      >;
      setFilters((prev) => ({
        ...prev,
        [rangeKey]: {
          ...prev[rangeKey],
          [type]: date,
        },
      }));
    }
  };

  const handleColumnSelect = (column: SortKey) => {
    setSelectedColumn(column);
    setAnchorEl(null);
  };

  const handleFilterApply = () => {
    if (selectedColumn && filterValue) {
      setFilters((prev) => ({
        ...prev,
        text: [...prev.text, { column: selectedColumn, value: filterValue }],
      }));
      setSelectedColumn(null);
      setFilterValue("");
    }
  };

  const handleTextFilterRemove = (index: number) => {
    setFilters((prev) => ({
      ...prev,
      text: prev.text.filter((_, i) => i !== index),
    }));
  };

  const clearAllFilters = () => {
    setFilters({
      text: [],
      qualifiers: [],
      targetDateRange: { start: null, end: null },
    });
  };

  const handleOpenModal = (data: MetricTableRow) => {
    setSelectedCellData(data);
    setModalOpen(true);
    setQueryEnabled(false);
  };

  const handleCloseModal = () => {
    setSelectedCellData(null);
    setModalOpen(false);
    setQueryEnabled(true);
  };

  // Filter data
  const filteredData = useMemo(() => {
    // If in Simple Mode, only apply text and qualifier filters
    if (isSimpleMode) {
      return metricTableRows.filter((row) => {
        const textMatch = filters.text.every((filter) =>
          String(row[filter.column])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        );

        const qualifierMatch =
          filters.qualifiers.length === 0 ||
          filters.qualifiers.includes(row.qualifier);

        return textMatch && qualifierMatch;
      });
    }

    // Normal mode - apply all filters
    return metricTableRows.filter((row) => {
      const textMatch = filters.text.every((filter) =>
        String(row[filter.column])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
      );

      const qualifierMatch =
        filters.qualifiers.length === 0 ||
        filters.qualifiers.includes(row.qualifier);

      const targetDateMatch =
        (!filters.targetDateRange.start ||
          new Date(row.targetDate) >= filters.targetDateRange.start) &&
        (!filters.targetDateRange.end ||
          new Date(row.targetDate) <= filters.targetDateRange.end);

      return textMatch && qualifierMatch && targetDateMatch;
    });
  }, [metricTableRows, filters, isSimpleMode]);

  // Sort data
  const sortedData = [...filteredData].sort((a, b) => {
    const comparison = a[orderBy] < b[orderBy] ? -1 : 1;
    return order === "asc" ? comparison : -comparison;
  });

  const rowContent = (_index: number, row: MetricTableRow) => (
    <React.Fragment>
      {!showMetricName ? (
        <TableCell sx={{ width: columnWidths["firm"], padding: "8px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 0.5,
              minWidth: 0,
            }}
          >
            <IconButton
              size="small"
              sx={{
                padding: "2px",
                flexShrink: 0,
              }}
              onClick={() => handleOpenModal(row)}
            >
              {getCompanyLogo(row.publishingOrganization)}
            </IconButton>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {row.firm}
            </span>
          </Box>
        </TableCell>
      ) : (
        <TableCell sx={{ width: columnWidths["metricName"] }}>
          {row.metricName}
        </TableCell>
      )}
      <TableCell sx={{ width: columnWidths["metricValue"] }}>
        {row.metricValue}
      </TableCell>
      <TableCell sx={{ width: columnWidths["qualifier"] }}>
        {row.qualifier}
      </TableCell>
      <TableCell sx={{ width: columnWidths["targetDate"] }}>
        {row.targetDate}
      </TableCell>
    </React.Fragment>
  );

  const fixedHeaderContent = () => (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.key}
          align="left"
          sx={{
            width: columnWidths[column.key],
            backgroundColor: "#f8f9fa !important",
            fontWeight: "bold",
          }}
        >
          <TableSortLabel
            active={orderBy === column.key}
            direction={orderBy === column.key ? order : "asc"}
            onClick={() => handleSort(column.key)}
          >
            {column.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );

  const convertToChartData = (): ChartData => {
    // Group data by target date
    const groupedByDate = filteredData.reduce((acc, row) => {
      if (!acc[row.targetDate]) {
        acc[row.targetDate] = {};
      }
      acc[row.targetDate][`${row.firm} - ${row.qualifier}`] = parseFloat(row.metricValue);
      return acc;
    }, {} as Record<string, Record<string, number>>);

    // Convert grouped data to ChartData format, with sorted dates
    return {
      chartType: ChartData_ChartType.BAR,
      description: "placeholder description",
      title: "",
      xAxisLabel: "Target Date",
      yAxisLabel: "",
      xAxisDataKey: "targetDate",
      dataPoints: Object.entries(groupedByDate)
        .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime())
        .map(([date, values]) => ({
          fields: [
            {
              key: "targetDate",
              stringValue: date,
            },
            ...Object.entries(values).map(([key, value]) => ({
              key,
              floatValue: value,
            })),
          ],
        })),
    };
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          ...(preview
            ? { overflow: "hidden" }
            : {
                width: "33.333%",
                minWidth: "600px",
                margin: "0 auto",
                overflow: "hidden",
              }),
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                letterSpacing: "-0.02em",
              }}
            >
              {tableName}
            </Typography>

            {/* Filter section */}
            {!disableFilters && (
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    startIcon={<FilterListIcon />}
                    variant="outlined"
                    size="small"
                    onClick={handleFilterClick}
                  >
                    Add Text Filter
                  </Button>
                  <Button
                    startIcon={<FilterListIcon />}
                    variant="outlined"
                    size="small"
                    onClick={handleQualifierFilterClick}
                  >
                    Qualifier Filter
                  </Button>
                  {!isSimpleMode && (
                    <Button
                      startIcon={<CalendarTodayIcon />}
                      variant="outlined"
                      size="small"
                      onClick={(e) => handleDateFilterClick(e, "target")}
                    >
                      Target Date
                    </Button>
                  )}
                  {(filters.text.length > 0 ||
                    filters.qualifiers.length > 0 ||
                    (!isSimpleMode &&
                      (filters.targetDateRange.start ||
                        filters.targetDateRange.end))) && (
                    <Button
                      startIcon={<ClearIcon />}
                      variant="outlined"
                      size="small"
                      onClick={clearAllFilters}
                      color="error"
                    >
                      Clear All
                    </Button>
                  )}
                  <ToggleButtonGroup
                    value={viewType}
                    exclusive
                    onChange={(_, newView) => newView && setViewType(newView)}
                    size="small"
                  >
                    <ToggleButton value="table">
                      <TableChartIcon />
                    </ToggleButton>
                    <ToggleButton value="chart">
                      <ShowChartIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {/* Active filters */}
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: "wrap", gap: 1 }}
                >
                  {filters.text.map((filter, index) => (
                    <Chip
                      key={`text-${index}`}
                      label={`${filter.column}: ${filter.value}`}
                      onDelete={() => handleTextFilterRemove(index)}
                      size="small"
                    />
                  ))}
                  {filters.qualifiers.map((qualifier) => (
                    <Chip
                      key={`qualifier-${qualifier}`}
                      label={`Qualifier: ${qualifier}`}
                      onDelete={() => handleQualifierChange(qualifier)}
                      size="small"
                    />
                  ))}
                  {!isSimpleMode && filters.targetDateRange.start && (
                    <Chip
                      label={`Target after: ${filters.targetDateRange.start.toLocaleDateString()}`}
                      onDelete={() => handleDateRangeChange(null, "start")}
                      size="small"
                    />
                  )}
                  {!isSimpleMode && filters.targetDateRange.end && (
                    <Chip
                      label={`Target before: ${filters.targetDateRange.end.toLocaleDateString()}`}
                      onDelete={() => handleDateRangeChange(null, "end")}
                      size="small"
                    />
                  )}
                </Stack>

                {/* Qualifier Filter Popover */}
                <Popover
                  open={Boolean(qualifierAnchorEl)}
                  anchorEl={qualifierAnchorEl}
                  onClose={() => setQualifierAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <FormGroup>
                      {uniqueQualifiers.map((qualifier) => (
                        <FormControlLabel
                          key={qualifier}
                          control={
                            <Checkbox
                              checked={filters.qualifiers.includes(qualifier)}
                              onChange={() => handleQualifierChange(qualifier)}
                            />
                          }
                          label={qualifier}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Popover>

                {/* Date Range Popover */}
                {!isSimpleMode && (
                  <Popover
                    open={Boolean(dateAnchorEl)}
                    anchorEl={dateAnchorEl}
                    onClose={() => {
                      setDateAnchorEl(null);
                      setActiveDateFilter(null);
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <DatePicker
                        label="Start Date"
                        value={filters.targetDateRange.start}
                        onChange={(date) =>
                          handleDateRangeChange(date, "start")
                        }
                      />
                      <DatePicker
                        label="End Date"
                        value={filters.targetDateRange.end}
                        onChange={(date) => handleDateRangeChange(date, "end")}
                      />
                    </Box>
                  </Popover>
                )}

                {/* Column selection menu */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {columns.map((column) => (
                    <MenuItem
                      key={column.key}
                      onClick={() => handleColumnSelect(column.key)}
                    >
                      {column.label}
                    </MenuItem>
                  ))}
                </Menu>

                {/* Text filter input */}
                {selectedColumn && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder={`Filter ${
                        columns.find((col) => col.key === selectedColumn)?.label
                      }...`}
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      sx={{ width: 200 }}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleFilterApply}
                      disabled={!filterValue}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setSelectedColumn(null)}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            <Paper
              sx={{
                height: 400,
                width: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: "12px",
                background: "linear-gradient(145deg, #ffffff, #f6f7f9)",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
              }}
            >
              {viewType === 'table' ? (
                <TableVirtuoso
                  data={sortedData}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={rowContent}
                  style={{
                    height: "100%",
                  }}
                />
              ) : (
                <Box 
                  sx={{ 
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '16px'
                  }}
                >
                  <Box 
                    sx={{ 
                      width: '100%',
                      height: '100%',
                      minHeight: '350px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <ChartRenderer chartData={convertToChartData()}/>
                  </Box>
                </Box>
              )}
            </Paper>
          </Paper>
        </LocalizationProvider>
      </Box>
      <CitationModal
        chunkId={selectedCellData?.chunkId}
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </Container>
  );
};

export default MetricTable;

const getCompanyLogo = (company: Company) => {
  switch (company) {
    case Company.JP_MORGAN_CHASE:
      return <JPMorganChase height={12} width={12} />;
    case Company.BANK_OF_AMERICA:
      return <Bofa height={12} width={12} />;
    case Company.GOLDMAN_SACHS:
      return <GoldmanSachs height={12} width={12} />;
    case Company.MORGAN_STANLEY:
      return <MorganStanley height={12} width={12} />;
    case Company.WELLS_FARGO:
      return <WellsFargo height={12} width={12} />;
    case Company.CITIGROUP:
      return <Citigroup height={12} width={12} />;
    case Company.BARCLAYS:
      return <Barclays height={12} width={12} />;
    case Company.DEUTSCHE_BANK:
      return <DeutscheBank height={12} width={12} />;
    case Company.CREDIT_SUISSE:
      return <CreditSuisse height={12} width={12} />;
    case Company.UBS:
      return <UBS height={12} width={12} />;
    case Company.HSBC:
      return <HSBC height={12} width={12} />;
    case Company.NOMURA:
      return <Nomura height={12} width={12} />;
    default:
      return <MenuIcon fontSize="small" />;
  }
};
