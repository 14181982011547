import React, { useContext } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BaseUrlContext } from "./Contexts";
import LoginPage from "./components/LoginPage";
import { AuthProvider, useAuth } from "./context/AuthContext";
import {
  DocumentExtraction,
  DocumentMetadata,
  DocumentStatus,
} from "./generated/protos/document";
import { DocumentExtractionRequest } from "./generated/protos/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDocumentExtractions } from "./api/document-extractions";
import {
  DocumentProvider,
  useDocumentContext,
} from "./context/DocumentContext";
import { ChatMessagesProvider } from "./context/ChatMessagesContext";
import {
  KeyThemesProvider,
  useKeyThemesContext,
} from "./context/KeyThemesContext";
import fetchKeyThemes from "./api/key-themes";
import { NavigationProvider, useNavigation } from "./context/NavigationContext";
import PageDashboard from "./components/Dashboard";
import Charts from "./components/Charts";

export default function App() {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  if (!baseUrl) {
    throw new Error("REACT_APP_BASE_API_URL is not set");
  }
  const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
  if (!oauthClientId) {
    throw new Error("REACT_APP_OAUTH_CLIENT_ID is not set");
  }

  return (
    <BaseUrlContext.Provider value={baseUrl}>
      <AuthProvider oauthClientId={oauthClientId}>
        <DocumentProvider>
          <ChatMessagesProvider>
            <KeyThemesProvider>
              <AppContent />
            </KeyThemesProvider>
          </ChatMessagesProvider>
        </DocumentProvider>
      </AuthProvider>
    </BaseUrlContext.Provider>
  );
}

function createDocumentExtractionRequest(
  allDocumentExtractions: DocumentExtraction[],
  userEmail: string
): DocumentExtractionRequest {
  const existingMetadatas = allDocumentExtractions
    .map((doc) => doc.documentMetadata)
    .filter((doc) => doc !== undefined) as DocumentMetadata[];

  return DocumentExtractionRequest.create({
    existingDocumentMetadatas: existingMetadatas,
    userEmail: userEmail,
  });
}

const Dashboard: React.FC = () => {
  const {
    allDocuments,
    setAllDocuments,
    selectedDocuments,
    setSelectedDocuments,
    queryEnabled,
    setIsLoading,
  } = useDocumentContext();
  const { setKeyThemes } = useKeyThemesContext();

  const baseUrl = useContext(BaseUrlContext);
  const { username } = useAuth();
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery({
    queryKey: ["documentExtractions", username],
    queryFn: () =>
      getDocumentExtractions(
        baseUrl,
        createDocumentExtractionRequest(allDocuments, username)
      ),
    refetchInterval: queryEnabled ? 10000 : false, // 10 seconds
    enabled: queryEnabled,
  });

  React.useEffect(() => {
    if (allDocuments.length === 0) {
      setIsLoading(isFetching);
    }
    else {
      setIsLoading(false);
    }
  }, [isFetching, allDocuments.length, setIsLoading]);

  const { data: keyThemesResponse } = useQuery({
    queryKey: ["keyThemes", selectedDocuments.map(docMetadata => docMetadata.documentId)],
    queryFn: ({ signal }) =>
      fetchKeyThemes(baseUrl, selectedDocuments, allDocuments, signal),
    enabled: selectedDocuments.length > 0,
  });

  React.useEffect(() => {
    if (keyThemesResponse) {
      setKeyThemes(keyThemesResponse.keyThemes);
    }
  }, [keyThemesResponse, setKeyThemes]);

  React.useEffect(() => {
    if (data != null && data.newDocumentExtractions.length > 0) {
      // for existing document extractions that were in progress, replace them with their completed/errored versions (same documentId)
      const newDocumentIds = data.newDocumentExtractions
        .map((doc) => doc.documentMetadata?.documentId)
        .filter((id) => id !== undefined) as string[];
      setAllDocuments((prevDocs) => [
        ...prevDocs.filter(
          (doc) =>
            !newDocumentIds.includes(doc.documentMetadata?.documentId || "")
        ),
        ...data.newDocumentExtractions,
      ]);
      // automatically select new documents to show in the dashboard
      setSelectedDocuments((prevSelectedDocs) => [
        ...prevSelectedDocs,
        ...(data.newDocumentExtractions
          .map((doc) => doc.documentMetadata)
          .filter(
            (doc) =>
              doc?.documentStatus === DocumentStatus.DOCUMENT_STATUS_COMPLETED
          )
          .filter((doc) => doc !== undefined) as DocumentMetadata[]),
      ]);
    }
  }, [data, setAllDocuments, setSelectedDocuments]);

  React.useEffect(() => {
    if (selectedDocuments.length > 0) {
      queryClient.invalidateQueries({ queryKey: ["keyThemes"] });
      queryClient.invalidateQueries({ queryKey: ["analytics"] });
    }
    if (selectedDocuments.length === 0) {
      setKeyThemes([]);
    }
  }, [selectedDocuments, setKeyThemes, queryClient])

  return (
    <NavigationProvider>
      <Header />
      <MainContent />
      <Footer />
    </NavigationProvider>
  );
};

const AppContent: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Dashboard /> : <LoginPage />;
};

function MainContent() {
  const { activeComponent } = useNavigation();

  switch (activeComponent) {
    case "Dashboard":
      return <PageDashboard />;
    case "Analysis":
      return <Charts />;
    default:
      return <PageDashboard />;
  }
}
